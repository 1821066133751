<template>
    <v-form ref="form">
        <v-dialog v-model="dialog" max-width="600" class="ma-0 pa-0" fullscreen persistent>
            <template v-slot:activator="{ on, attrs }">

                <v-btn v-bind="attrs" v-on="on" icon class="custom-btn">
                    <v-icon small class="ma-3" color="white">mdi-bullhorn-variant-outline</v-icon>
                </v-btn>
            </template>
            <v-card class="pa-0 ma-0">
                <v-row dense>
                    <v-col class="text-right">
                        <v-icon class="mx-3" @click="dialog = false">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-card-title>
                    BIO MARCADOR / EXAME FUNDO DE OLHO
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <br><span class="h4 ml-2">Paciente: <b>{{ currentItem.nome }}</b></span>
                    <v-card class="pa-2 ma-0">
                        <v-row dense>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group class="text-right" row label="OD SEM ALTERAÇÃO" required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.od_sem_alteracao">
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>

                                    <template v-slot:label>
                                        <span class="azul">OD SEM ALTERAÇÃO</span>
                                    </template>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.od_rdnp_leve">

                                    <template v-slot:label>
                                        <span class="azul">OD RDNP LEVE</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.od_rdnp_moderada">

                                    <template v-slot:label>
                                        <span class="azul">OD RDNP MODERADO</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.od_rdnp_grave_m_grave">

                                    <template v-slot:label>
                                        <span class="azul">OD RDNP GRAVE M GRAVE</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.od_rdp">

                                    <template v-slot:label>
                                        <span class="azul">OD RDP</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.od_edema_macular">

                                    <template v-slot:label>
                                        <span class="azul">OD EDEMA MACULAR</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-row>
                                <v-col>
                                    <v-divider class="ma-1"></v-divider>
                                </v-col>
                            </v-row>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.os_sem_alteracao">

                                    <template v-slot:label>
                                        <span class="roxo">OE SEM ALTERAÇÃO</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.os_rdnp_leve">

                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP LEVE</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.os_rdnp_moderada">

                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP MODERADO</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.os_rdnp_grave_m_grave">

                                    <template v-slot:label>
                                        <span class="roxo">OE RDNP GRAVE M GRAVE</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.os_rdp">

                                    <template v-slot:label>
                                        <span class="roxo">OE RDP</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required
                                    :rules="[e => !!e || 'Campo obrigatório!']" v-model="currentItem.os_edema_macular">

                                    <template v-slot:label>
                                        <span class="roxo">OE EDEMA MACULAR</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider class="ma-1"></v-divider>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.catarata">

                                    <template v-slot:label>
                                        <span class="verde">CATARATA</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.glaucoma">

                                    <template v-slot:label>
                                        <span class="verde">GLAUCOMA</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-radio-group row required :rules="[e => !!e || 'Campo obrigatório!']"
                                    v-model="currentItem.outras">

                                    <template v-slot:label>
                                        <span class="verde">OUTRAS</span>
                                    </template>
                                    <v-radio value="1" label="SIM"></v-radio>
                                    <v-radio value="0" label="NÃO"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col dense :cols="12" class="text-right">
                                <v-textarea v-model="currentItem.observacoes_doencas_olhos">
                                    <template v-slot:label>
                                        <span class="verde">OUTRAS DOENÇAS DOS OLHOS</span>
                                    </template>
                                </v-textarea>
                            </v-col>
                            <v-col dense :cols="12" class="text-right">
                                <v-textarea label="OBSERVAÇÕES" v-model="currentItem.observacoes"> 
                                    
                                    <template v-slot:label>
                                        <span class="cinza">OBSERVAÇÕES</span>
                                    </template>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-row dense>
                        <v-col dense :cols="12">
                            <v-btn :loading="loading" outlined block
                                @click="post_lista()"><v-icon>mdi-content-save</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-row min-height="20">
                    <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
import httpApiService from '@/http'
export default {
    name: 'ExameFundoOlho',
    props: { currentItem: { type: Object } },
    data: () => ({
        dialog: false,
        loading: false,
        msg: '',
        sexos: [],
        orgaos_expedidores: [],
        estados: [],
        frequencia_hemoglobina_glicada_diaria: [],
        cidades: [],
        problema_pes: [],
    }),
    methods: {
        get_listas(url, objeto) {
            this.loading = true
            httpApiService.get(url)
                .then(res => {
                    this[objeto] = res.data
                })
                .finally(() => { this.loading = false })
        },

        async post_lista() {
            this.loading = true
            if (!this.$refs.form.validate()) {
                this.loading = false
                return false
            }

            const response = await httpApiService.patch(`mutirao-sus/paciente/${this.currentItem.id}/`, this.currentItem)

            if (response.status === 200) {
                this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                this.$emit('atualiza')
            } else {
                for (const i in response.response.data) {
                    this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                }
            }
            this.loading = false

        },

    },
    mounted() {
    },

}
</script>
<style>
.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important;
    /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important;
    /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
}

.azul {
    color: rgb(31, 49, 128)
}
.roxo {
    color: rgb(148, 3, 141)
}
.verde {
    color: rgb(10, 112, 32)
}
.cinza {
    color: rgb(16, 17, 16)
}
</style>